import React, { useState } from "react";

import capitalize from "lodash/capitalize";
import _ from 'lodash'
import isEmpty from "lodash/isEmpty";

import type { OcrResult } from 'shared/interfaces/api/document'
import type { ExpandIconProps } from 'antd/lib/table'
import type { IncomeStream } from "renter/interfaces/api/income";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";
import { CollapseButton } from "shared/components/Tile/styled";
import { RegularText, SmallText } from "shared/components/Typography";
import { COLORS, colors, isProductionEnv } from "shared/config/constants";
import { MAX_INCOMES_NUMBER_IN_STREAM } from "shared/config/constants";
import { isManagerApp } from "shared/utils/auth";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { mapIncomeStreamVerificationMethodToSource } from "shared/utils/employmentAndIncome";
import { getFraudRiskTextAndColor } from 'shared/utils/document'

import { StreamDetailTable } from "./StreamDetailTable";
import { IncomeStreamsTable as StyledTable, IncomeStreamHeader, MoreLink } from "./styled";

interface IncomeStreamsTableProps {
  streams: IncomeStream[];
  type: "excluded" | "included";
  ocrResults?: OcrResult;
  canViewOcrResults: boolean;
  canEditOcrResults: boolean;
}

export const IncomeStreamsTable = ({
  streams,
  type,
  ocrResults,
  canViewOcrResults,
  canEditOcrResults,
}: IncomeStreamsTableProps) => {
  const [viewExclusion, setViewExclusion] = useState({})
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const renderFraudRisk = isManagerApp() && canViewOcrResults && type === 'excluded'
  const renderExclusionReasons = type === 'excluded'

  const expandIconColumnIndex = () => {
    let index = 3
    if (renderFraudRisk)
      index += 1
    if (renderExclusionReasons)
      index += 1
    return index
  }

  const getHighestFraudRiskLevel = (ocrResultIds) => {
    const results = _.map(ocrResultIds, (sourceOcrResult) => (_.find(ocrResults, {id: sourceOcrResult})))
    const riskLevels = _.chain(results)
      .map('fraudRiskLevel')
      .value()

    if (_.includes(riskLevels, 'VERY_HIGH')) {
      return 'VERY_HIGH'
    } else if (_.includes(riskLevels, 'HIGH')) {
      return 'HIGH'
    } else if (_.includes(riskLevels, 'MEDIUM')) {
      return 'MEDIUM'
    } else if (_.includes(riskLevels, 'LOW')) {
      return 'LOW'
    } else {
      return 'UNSUPPORTED'
    }
  }

  const determineFraudRisk = (ocrResultIds) => {
    const riskLevel = getHighestFraudRiskLevel(ocrResultIds)
    return getFraudRiskTextAndColor(riskLevel)
  }

  const handleMoreClick = (identifier) => {
    setViewExclusion({
      ...viewExclusion,
      [identifier]: !viewExclusion[identifier]
    })
  }

  return (
    <>
      {!isEmpty(streams) && (
        <Tile.Inner noPaddingTop>
          <FlexContainer alignItems="center" flexDirection="row" gap="8px">
            <Icon.InfoBlueIcon height={16} />
            <SmallText>
              {`VERO displays up to ${MAX_INCOMES_NUMBER_IN_STREAM} supporting documents or transactions for
              each income stream.`}
            </SmallText>
          </FlexContainer>
          <StyledTable
            columns={[
              {
                title: <IncomeStreamHeader>Source</IncomeStreamHeader>,
                dataIndex: "source",
                render: (_, { description }) => (
                  <RegularText>{description}</RegularText>
                ),
              },
              ...(renderExclusionReasons
                ? [
                    {
                      title: <IncomeStreamHeader>Exclusion Reason(s)</IncomeStreamHeader>,
                      dataIndex: "fraudRisk",
                      render: (_text, {identifier, excludedReasons}) => {
                        return (
                          <FlexContainer>
                            <RegularText>
                              <ul>
                                {_.map(excludedReasons, (reason, index) => (
                                  <li style={{ listStyleType: "unset", display: Number(index) === 0 || viewExclusion[identifier] ? "list-item" : "none" }}>
                                    {reason}
                                  </li>
                                ))}
                              </ul>
                            </RegularText>
                            <FlexContainer alignItems="end">
                              <MoreLink
                                style={{display: excludedReasons && excludedReasons.length > 1 && !viewExclusion[identifier] ? "unset" : "none"}}
                                onClick={(e) => { e.stopPropagation(); handleMoreClick(identifier);}}
                              >
                                View
                              </MoreLink>
                            </FlexContainer>
                          </FlexContainer>
                        )
                      },
                    },
                  ]
                : []
              ),
              ...(renderFraudRisk
                ? [
                    {
                      title: (
                        <FlexContainer
                          alignItems="center"
                          flexDirection="row"
                          gap="4px"
                        >
                          <IncomeStreamHeader>Fraud Risk</IncomeStreamHeader>
                          <Icon.BlueQuestionMark
                            height={14}
                            cursor="pointer"
                            width={14}
                            onClick={() =>
                              window?.Intercom?.(
                                'showArticle',
                                isProductionEnv ? 9621394 : 9641772,
                              )
                            }
                          />
                        </FlexContainer>
                      ),
                      dataIndex: "fraudRisk",
                      render: (_text, { incomes }) => {
                        const sourceOcrResults = _.map(incomes, (income) => (income.sourceOcrResult))
                        const fraudRisk = determineFraudRisk(sourceOcrResults)
                        return (
                          <RegularText style={{ color: fraudRisk.color}} >
                            {fraudRisk.text}
                          </RegularText>
                        )
                      },
                    },
                  ]
                : []
              ),
              {
                title: <IncomeStreamHeader>Verification</IncomeStreamHeader>,
                dataIndex: "verification",
                render: (_, { streamVerificationMethod }) => (
                  <RegularText>
                    {mapIncomeStreamVerificationMethodToSource(
                      streamVerificationMethod
                    )}
                  </RegularText>
                ),
              },
              {
                title: <IncomeStreamHeader>Pay Frequency</IncomeStreamHeader>,
                dataIndex: "payFrequency",
                render: (_, { frequency }) => (
                  <RegularText>{capitalize(frequency)}</RegularText>
                ),
              },
              {
                title: <IncomeStreamHeader>Projected Annual Income</IncomeStreamHeader>,
                dataIndex: "income",
                render: (
                  _,
                  { estimatedAnnualGrossAmount, estimatedAnnualNetAmount }
                ) => (
                  <RegularText>
                    {printDollarsFromCents(
                      estimatedAnnualGrossAmount ??
                        estimatedAnnualNetAmount ??
                        0
                    )}{" "}
                    {type === "included" &&
                      `(${estimatedAnnualGrossAmount ? "Gross" : "Net"})`}
                  </RegularText>
                ),
              },
            ]}
            expandIcon={({ record, expanded, onExpand }: ExpandIconProps<IncomeStream>) => (
               !isEmpty(record?.incomes) ? (
                <CollapseButton
                  collapsed={!expanded}
                  collapsibleOnDesktop
                  collapsibleOnMobile
                  onClick={onExpand}
                  style={{ float: "right" }}
                >
                  <Icon.ChevronDownIcon />
                </CollapseButton>
              ) : undefined
            )}
            expandIconAsCell={false}
            expandIconColumnIndex={expandIconColumnIndex()}
            expandedRowKeys={expandedRowKeys}
            onRow={(record: IncomeStream) => ({
              onClick: () => {
                if (isEmpty(record?.incomes))
                  return
                if (expandedRowKeys.includes(record.id))
                  setExpandedRowKeys((prev) => prev.filter((id) => record.id !== id))
                else
                  setExpandedRowKeys((prev) => [...prev, record.id])
              },
            })}
            rowClassName={(record: IncomeStream) =>
              isEmpty(record?.incomes) && 'not-expandable'
            }
            expandRowByClick
            expandedRowRender={(stream) => (
              <StreamDetailTable incomeStream={stream as IncomeStream} canViewOcrResults={canViewOcrResults} canEditOcrResults={canEditOcrResults} />
            )}
            dataSource={streams}
            noBorderBottom
            noClickableRows
            pagination={false}
            rowKey={({ id }) => id}
            scroll={{ x: true }}
            size="middle"
          />
        </Tile.Inner>
      )}
      {isEmpty(streams) && (
        <Tile.Inner key={0} data-testid={`income-stream-${0}`} noPaddingTop>
          <FlexContainer
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            gap="8px"
          >
            <Icon.InfoBlueIcon />
            <RegularText>
              {type === "excluded"
                ? "There is no excluded income available for this application."
                : "There is no income available for this application."}
            </RegularText>
          </FlexContainer>
        </Tile.Inner>
      )}
    </>
  );
};
