import React, { useEffect, useState } from "react";

import { ModalProps as BaseModalProps } from "antd/lib/modal";

import { BUTTON_SIZES } from "shared/components/Button";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";

import {
  ModalStyled,
  Body,
  SubmitFooter,
  SubmitButton,
  Subtitle,
  Footer,
  FooterLeft,
  FooterRight,
} from "./styled";

export enum SIZES {
  sm = "sm",
  default = "default",
  lg = "lg",
}

export type ModalProps = BaseModalProps & {
  id?: string;
  title?: string;
  titleIcon?: object;
  close: () => void;
  afterClose: () => void;
  submitButtonLabel?: string;
  submitButtonSize?: SIZES;
  submitButtonDisabled?: boolean;
  submitButtonCentered?: boolean;
  submitButtonFullWidth?: boolean;
  showSubmitButton?: boolean;
  subtitle?: React.ReactNode;
  submit?: (obj: object) => void;
  submitting?: boolean;
  context?: object;
  showCancelLink?: boolean;
  disableCancelLink?: boolean;
  cancelLinkLabel?: string;
  footer?: React.ReactNode;
  visible?: boolean;
  init?: () => void;
  initOnlyOnce?: boolean;
  closeOnSubmit?: boolean;
  submitButtonHTMLType?: string;
  onCancelLinkClick?: () => void;
  subtitleProps?: {
    noMarginTop?: boolean;
  };
  submitFooter?: React.ReactNode;
  closable?: boolean;
  showFooter?: boolean;
  showTitle?: boolean;
  submitButtonSuffixIcon?: React.ReactElement;
  style?: object;
  fullScreen?: boolean;
};

const _Modal: React.FC<ModalProps> = ({
  children,
  close,
  onCancelLinkClick,
  afterClose,
  submit,
  submitting,
  showSubmitButton = true,
  submitButtonLabel = "Submit",
  submitButtonSize = BUTTON_SIZES.default,
  submitButtonDisabled,
  submitButtonFullWidth,
  context,
  showCancelLink = true,
  disableCancelLink = false,
  cancelLinkLabel = "Cancel",
  title,
  titleIcon,
  subtitle,
  subtitleProps = {},
  footer,
  visible,
  init,
  initOnlyOnce,
  closeOnSubmit = true,
  submitButtonHTMLType = "submit",
  submitButtonCentered,
  submitFooter,
  submitButtonSuffixIcon,
  closable = true,
  showTitle = true,
  showFooter = true,
  ...props
}) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (visible && init && (!initialized || !initOnlyOnce)) {
      init();
      setInitialized(true);
    }
  }, [visible]);

  const ModalFooter = showFooter && (
    <Footer>
      {footer && <FooterLeft>{footer}</FooterLeft>}
      <FooterRight
        className="modal-footer-right"
        centered={submitButtonCentered}
      >
        {showCancelLink && (
          <UnderlineLink
            onClick={onCancelLinkClick || close}
            data-testid="close-link"
            disabled={disableCancelLink}
          >
            {cancelLinkLabel}
          </UnderlineLink>
        )}
        {showSubmitButton && (
          <SubmitFooter fullWidth={submitButtonFullWidth}>
            <SubmitButton
              type="primary"
              block={submitButtonFullWidth}
              noMargin={submitButtonCentered}
              onClick={async () => {
                if (submit) {
                  await submit(context);
                }
                if (closeOnSubmit) {
                  close();
                }
              }}
              data-testid="submit-button"
              size={submitButtonSize}
              disabled={submitButtonDisabled}
              htmlType={submitButtonHTMLType}
              loading={submitting}
              SuffixIcon={submitButtonSuffixIcon}
            >
              {submitButtonLabel}
            </SubmitButton>
            {submitFooter}
          </SubmitFooter>
        )}
      </FooterRight>
    </Footer>
  );

  return (
    <ModalStyled
      closeIcon={<Icon.ModalCloseIcon />}
      title={
        showTitle && (
          <>
            <div className="ant-modal-title" data-testid="title">
              {titleIcon}
              {title}
            </div>
            {subtitle && <Subtitle {...subtitleProps}>{subtitle}</Subtitle>}
          </>
        )
      }
      onCancel={closable ? close : undefined}
      closable={closable}
      onOk={submit}
      afterClose={afterClose}
      visible={visible}
      destroyOnClose
      footer={ModalFooter}
      showFooter={showFooter}
      {...props}
    >
      {children}
    </ModalStyled>
  );
};

export const Modal = Object.assign(_Modal, { Body });
