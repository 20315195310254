import React, { useContext } from 'react'

import type { ExpandIconProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import _, { isEmpty, values } from 'lodash'

import type { Income, IncomeStream } from 'renter/interfaces/api/income'
import Icon from 'shared/components/Icon'
import { IconLink } from 'shared/components/Links'
import { CollapseButton } from 'shared/components/Tile/styled'
import { RegularText } from 'shared/components/Typography'
import {
  useGetOcrResultById,
  usePatchOcrResultById,
} from 'shared/hooks/api/applicationQueries'
import useModal from 'shared/hooks/useModal'
import { isManagerApp } from 'shared/utils/auth'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import {
  mapIncomeStreamVerificationMethodToType,
  editableStreamVerificationMethodType,
} from 'shared/utils/employmentAndIncome'

import ApplicationContext from '../../applicationContext'

import EditStreamDetailModal from './EditStreamDetailModal'
import OcrolusAccordion from './OcrolusAccordion'
import {
  DocumentLink,
  IncomeStreamDetailTable,
  IncomeStreamHeader,
  ActionsContainer,
  EditedChip,
} from './styled'

interface StreamDetailTableProps {
  incomeStream: IncomeStream
  canViewOcrResults: boolean
  canEditOcrResults: boolean
}

export const StreamDetailTable = ({
  incomeStream,
  canViewOcrResults,
  canEditOcrResults,
}: StreamDetailTableProps) => {
  const { incomes, streamVerificationMethod } = incomeStream
  const { application } = useContext(ApplicationContext)
  const { openModalDialog } = useModal()
  const { dealDecisionIsMade } = application
  const { patchOcrResultById } = usePatchOcrResultById()
  const hasDocumentUrl = incomeStream.incomes?.some(
    ({ documentUrl }) => !!documentUrl,
  )

  _.chain(incomes)
    .map((income) => {
      const sourceOcrResult = _.get(income, 'sourceOcrResult')
      if (sourceOcrResult) {
        const { ocrResult } = useGetOcrResultById(sourceOcrResult)
        if (ocrResult) {
          _.assign(income, { ocrResult })
        }
      }
      return income
    })
    .value()

  const renderEditModal = (stream) => {
    return openModalDialog(EditStreamDetailModal, {
      stream,
      submit: ({ id, data }) => {
        if (data.netAmount) {
          // eslint-disable-next-line no-self-assign
          data.netAmount = data.netAmount
        }
        if (data.grossAmount) {
          // eslint-disable-next-line no-self-assign
          data.grossAmount = data.grossAmount
        }
        patchOcrResultById({ id, data })
      },
    })
  }

  const columns = [
    {
      title: <IncomeStreamHeader>Type</IncomeStreamHeader>,
      dataIndex: 'type',
      render: (_, { incomeVerificationMethod }) => (
        <RegularText>
          {mapIncomeStreamVerificationMethodToType(
            incomeVerificationMethod,
          )}
        </RegularText>
      ),
    },
    {
      title: (
        <IncomeStreamHeader>
          {['ocr-1099', 'ocr-w2'].includes(streamVerificationMethod)
            ? 'Tax Year'
            : 'Payment Date'}
        </IncomeStreamHeader>
      ),
      // eslint-disable-next-line prettier/prettier
      dataIndex: "paymentDate",
      render: (_, { incomeVerificationMethod, dateReceived, year }) => (
        <RegularText>
          {['ocr-1099', 'ocr-w2'].includes(incomeVerificationMethod)
            ? year
            : dayjs(dateReceived).format('MM/DD/YYYY')}
        </RegularText>
      ),
    },
    {
      title: <IncomeStreamHeader>Gross Amount</IncomeStreamHeader>,
      dataIndex: 'grossAmount',
      render: (_, { grossAmount }) => (
        <RegularText>
          {grossAmount ? printDollarsFromCents(grossAmount) : 'N/A'}
        </RegularText>
      ),
    },
    {
      title: <IncomeStreamHeader>Net Amount</IncomeStreamHeader>,
      dataIndex: 'netAmmount',
      render: (_, { netAmount }) => (
        <RegularText>
          {printDollarsFromCents(netAmount ?? 0)}
        </RegularText>
      ),
    },
    ...(canEditOcrResults || (!dealDecisionIsMade && hasDocumentUrl)
      ? [
          {
            title: '',
            dataIndex: 'actions',
            render: (__, stream) => {
              return (
                <ActionsContainer>
                  {canEditOcrResults
                    && stream?.ocrResult?.annotation
                    && !values(stream?.ocrResult?.annotation).every(_.isEmpty)
                    && (<EditedChip
                          label="EDITED"
                          color="light-grey"
                        />)
                  }
                  {!dealDecisionIsMade && hasDocumentUrl && stream?.documentUrl && (
                    <DocumentLink
                      onClick={(e) => e.stopPropagation()}
                      href={stream?.documentUrl}
                      target="_blank"
                      type="link"
                    >
                      View
                    </DocumentLink>
                  )}
                  {canEditOcrResults
                  && stream?.sourceOcrResult && editableStreamVerificationMethodType(stream?.incomeVerificationMethod) && (
                      <IconLink
                        Icon={Icon.EditPencilLargeIcon}
                        // @ts-ignore
                        onClick={(e) => {e.stopPropagation(); renderEditModal(stream);}}
                      />
                    )
                  }
                </ActionsContainer>
              );
            }
          },
        ]
      : []),
  ]

  return (
    <>
      {!isEmpty(incomes) && (
        <IncomeStreamDetailTable
          columns={columns}
          // @ts-ignore
          expandedRowClassName={() => 'income-ocr-expanded'}
          expandIcon={({
            record,
            expanded,
            onExpand,
          }: ExpandIconProps<Income>) => {
            if (
              !record.ocrResult ||
              !isManagerApp() ||
              !canViewOcrResults ||
              !record.ocrResult?.hasFraudSignal
            ) {
              return null
            }
            return (
              <CollapseButton
                collapsed={!expanded}
                collapsibleOnDesktop
                collapsibleOnMobile
                onClick={onExpand}
                style={{
                  float: 'right',
                  height: '40px',
                }}
              >
                <Icon.ChevronDownIcon />
              </CollapseButton>
            )
          }}
          expandIconAsCell={false}
          expandIconColumnIndex={columns.length - 1}
          expandRowByClick
          expand
          style={{ cursor: 'pointer' }}
          expandedRowRender={(stream: Income) => {
            if (
              !stream.ocrResult ||
              !isManagerApp() ||
              !canViewOcrResults ||
              !stream.ocrResult?.hasFraudSignal
            ) {
              return
            }
            // eslint-disable-next-line consistent-return
            return (
              <OcrolusAccordion
                ocrolusSignal={stream.ocrResult}
                dealDecisionIsMade={dealDecisionIsMade}
              />
            )
          }}
          defaultExpandAllRows
          dataSource={incomes}
          noBorderBottom
          noClickableRows
          pagination={false}
          rowKey={({ id }) => id}
          size="small"
        />
      )}
    </>
  )
}
