import React from "react";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import InformationModal from "shared/components/Modals/InformationModal";
import { Modal } from "shared/components/Modal";
import Table from "shared/components/Table";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { ImageLink } from "shared/components/Links";
import {
  BREAKPOINT_RESOLUTIONS,
  COLORS,
  colors,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { formatDate } from "shared/utils/ui";

import {
  HeaderMainLabel,
  TileCustom,
  FraudSignalContainer,
  CapitalizedContainer,
  SupportingDataText,
} from "./styled";

const SIGNAL_STATUS = Object.freeze({
  VALID: "valid",
  INVALID: "invalid",
  REMOVED: "removed",
  FRAUDULENT: "fraudulent"
});

const SIGNAL_TYPES = Object.freeze({
  BANK_STATEMENT: {
    formType: "bank-statement",
    label: "Bank Statement",
  },
  PAYSTUB: {
    formType: "paystub",
    label: "PayStub",
  },
  W2: {
    formType: "w2",
    label: "W2",
  },
  UNCLASSIFIED: {
    formType: "unclassified",
    label: "Unclassified",
  },
  1040: {
    formType: "1040",
    label: "1040",
  },
  1099: {
    formType: "1099",
    label: "1099",
  },
  other: {
    formType: "other",
    label: "other",
  },
});

const OCROLUS_STATUS_VIEW_INFO = Object.freeze({
  [SIGNAL_STATUS.VALID]: {
    Icon: Icon.CheckGreenBig,
    color: COLORS.accentDark,
  },
  [SIGNAL_STATUS.INVALID]: {
    Icon: Icon.OrangeExclamationHollow,
    color: COLORS.darkOrange,
  },
  [SIGNAL_STATUS.REMOVED]: {
    Icon: Icon.GrayExclamationHollow,
    color: colors.neutral[600],
  },
  [SIGNAL_STATUS.FRAUDULENT]: {
    Icon: Icon.RedExclamationHollow,
    color: COLORS.failedRed,
  },
});

const fraudSignalColumns = [
  {
    title: "Fraud Type",
    dataIndex: "fraudSignals",
    key: "fraudSignals",
  },
  {
    title: "Instances",
    dataIndex: "instances",
    key: "instances",
  },
  {
    title: "Fraud Visualizations",
    dataIndex: "fraudVisualizations",
    key: "fraudVisualizations",
  },
  {
    title: "",
    dataIndex: "details",
    key: "details",
  },
];

const responsiveColumns = {
  xs: {
    column1: "fraudSignals",
    column2: "instances",
    column3: "details",
    column5: "fraudVisualizations",
  },
  sm: ["fraudSignals", "instances"],
  md: ["fraudSignals", "details", "instances"],
};

const supportingDataColumns = [
  {
    title: "Field Name",
    dataIndex: "key",
    key: "keyField",
  },
  {
    title: "Fraud Type",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const supportingResponsiveColumns = {
  xs: {
    column1: "key",
    column2: "name",
    column3: "value",
  },
  sm: ["key", "name"],
  md: ["key", "name", "value"],
};

const noDataPlaceholder = {
  Icon: Icon.NoDataIcon,
  label: "Signals",
};

const OcrolusAccordion = ({
  ocrolusSignal,
  dealDecisionIsMade,
}) => {
  const { openModalDialog } = useModal();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  const renderSupportingData = ({ key, name, value }) => {
    return {
      key,
      values: [
        <CapitalizedContainer>
          <RegularText>{key}</RegularText>
        </CapitalizedContainer>,
        <CapitalizedContainer>
          <RegularText>{name}</RegularText>
        </CapitalizedContainer>,
        <CapitalizedContainer>
          <RegularText>{value}</RegularText>
        </CapitalizedContainer>,
      ],
    };
  };

  const renderSignal = (signal) => {
    const hasFraudSignals = signal.fraudSignals.length > 0;
    const isRemoved = !_.isEmpty(_.get(signal, "removedAt"));
    const isFraudulent = _.get(signal, 'isFraudulent');
    const fraudClass = isFraudulent
      ? SIGNAL_STATUS.FRAUDULENT
      : isRemoved
        ? SIGNAL_STATUS.REMOVED
        : hasFraudSignals
          ? SIGNAL_STATUS.INVALID
          : SIGNAL_STATUS.VALID;
    const viewClass = hasFraudSignals ? "can-view" : "";

    return (
      <TileCustom
        className={`${fraudClass} ${viewClass}`}
        style={{ cursor: "auto" }}
      >
        {hasFraudSignals && (
          <Tile key={0} noBorder noMarginBottom>
            <div>
              {signal.fraudSignals.map((fraudSignal, index) => (
                <FraudSignalContainer>
                  <Icon.ArrowEnter />
                  {signal.fraudVisualizations.length > 0 && (
                    <FlexContainer>
                      {signal.fraudVisualizations.map((fraudVisualization) => (
                          <ImageLink 
                          width="16px"
                          height="16px"
                          color={COLORS.purple}
                          imageUrl={_.get(fraudVisualization, 'signedUrl')}
                        />
                        ))}

                    </FlexContainer>
                  )}
                  <SmallText
                    underline
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      openModalDialog(InformationModal, {
                        title: "Supporting Data",
                        description: (
                          <div>
                            <Table
                              id={`${fraudSignal.name}-${index}`}
                              columns={supportingDataColumns}
                              columnBreakpoints={
                                supportingResponsiveColumns
                              }
                              placeholder={noDataPlaceholder}
                              rows={fraudSignal.supportingData.map(
                                renderSupportingData
                              )}
                              pagination={false}
                              scroll={{ y: 600 }}
                              noClickableRows
                              alt
                            />
                          </div>
                        ),
                        centered: true,
                        width: 1000,
                      });
                    }}
                  >
                    Fraud Type: {fraudSignal.name}
                  </SmallText>
                </FraudSignalContainer>
              ))}
            </div>
          </Tile>
        )}
      </TileCustom>
    );
  };

  return (
    <Tile.Inner noPadding>
      {renderSignal(ocrolusSignal)}
    </Tile.Inner>
  );
};

export default OcrolusAccordion;
