import React from 'react'

import isEmpty from 'lodash/isEmpty'

import type { OcrResult } from 'shared/interfaces/api/document'
import type { ExcludedIncomeStream } from 'renter/interfaces/api/income'
import { MediumTitle, SmallText } from 'shared/components/Typography'

import { IncomeStreamsTable } from './IncomeStreamsTable'
import { Divider } from './styled'
import Tile from 'shared/components/Tile'

interface ExcludedIncomeProps {
  streams: ExcludedIncomeStream[]
  ocrResults: OcrResult
  canViewOcrResults: boolean
  canEditOcrResults: boolean
}

export const ExcludedIncomeSection = ({ streams, ocrResults, canViewOcrResults, canEditOcrResults }: ExcludedIncomeProps) => {
  return (
    <>
      <Divider />
      <Tile
        id="unverified-income"
        header={{
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
          title: <MediumTitle>Unverified Potential Income</MediumTitle>,
          subtitle: !isEmpty(streams) && (
            <SmallText>
              This discovered income is not included in the total income.
            </SmallText>
          ),
          withoutBorderBottom: true,
        }}
        rounded
      >
        <IncomeStreamsTable streams={streams} type="excluded" ocrResults={ocrResults} canViewOcrResults={canViewOcrResults} canEditOcrResults={canEditOcrResults} />
      </Tile>
    </>
  )
}
