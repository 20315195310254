import React, { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import {
  ProviderConfig,
  useFlags,
  useLDClient,
} from 'launchdarkly-react-client-sdk'

import {
  getCachedActiveCompanyId,
  getCachedActiveCompanySlug,
  getClaims,
  Token,
  useClaims,
} from './auth'

type Params = { token?: Token; companyId?: number; companySlug?: string }

const getLdUser = ({ token, companyId, companySlug }: Params = {}):
  | ProviderConfig['user'] => {
  token = token ?? getClaims()

  return {
    key: (token?.userId || token?.sub || 0).toString(),
    email: token?.email || token?.username,
    firstName: token?.givenName,
    lastName: token?.familyName,
    custom: {
      companyId: companyId ?? getCachedActiveCompanyId(),
      companySlug: companySlug ?? getCachedActiveCompanySlug(),
      env: process.env.REACT_APP_ENVIRONMENT,
    },
  }
}

const getKeys = (user?: ProviderConfig['user']) => ({
  key: user?.key,
  companyId: user?.custom?.companyId,
})

export const getLDConfig = (): ProviderConfig => {
  const config = {
    clientSideID: process.env.REACT_APP_LD_KEY,
    user: getLdUser(),
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value)
          },
        },
      ],
    },
  }
  console.log('Initialized Launch Darkly', getKeys(config.user))
  // @ts-ignore
  return config
}

// flag "app-summary-revamp" maps to "appSummaryRevamp"
export enum VeroFlag {
  useAttachmentsRedesign = 'useAttachmentsRedesign',
  enablePaymentAccountMapping = 'enablePaymentAccountMapping',
  enableExpressionEditor = 'enableExpressionEditor',
  enableSearchService = 'enableSearchService',
  useSearchService = 'useSearchService',
}

interface VeroFlags extends Record<VeroFlag, boolean> {}

const mapKeys = <T extends string | number | symbol, S>(
  obj: Record<T, S>,
  map: (key: string | number | symbol) => S,
) =>
  Object.keys(obj).reduce<Record<T, S>>((obj, key) => {
    obj[key] = map(key)
    return obj
  }, {} as Record<T, S>)

export const useVeroFlags = (): VeroFlags => {
  const flags = useFlags<VeroFlags>()
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search)
  const enableAll = params.has('new')
  const disableAll = params.has('old')
  if (enableAll) return { ...mapKeys(flags, () => true) }
  if (disableAll) return {} as VeroFlags
  return flags
}

const useSetupLaunchDarkly = () => {
  const client = useLDClient()
  const { claims, company } = useClaims()

  useEffect(() => {
    if (!client) return
    const user = getLdUser({ token: claims, companyId: company?.id })
    client
      .waitUntilReady()
      .then(() => client.identify(user))
      .catch((error) => {
        if (error) console.log(error?.message || error)
      })
    client.on('error', (error) => console.log(error?.message))

    console.log(`updated LDUser`, getKeys(user))
  }, [client, claims, company])
}

export const WatchLaunchDarkly = () => {
  useSetupLaunchDarkly()
  return <></>
}

export const useIsLDReady = (): boolean => useLDClient() !== undefined
