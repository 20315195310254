import React from "react";

import styled from "@emotion/styled";
import { Modal as BaseModal } from "antd";
import { ModalProps as BaseModalProps } from "antd/lib/modal";

import { Button } from "shared/components/Button";
import { colors, COLORS, MEDIA_QUERIES } from "shared/config/constants";

const bodyPaddingX = "48px";
const bodyPaddingXSm = "20px";

export const ModalStyled = styled(BaseModal)<
  { fullScreen?: boolean; showFooter?: boolean } & BaseModalProps
>((props) => ({
  width: props.width,
  ...(props.fullScreen && {
    maxWidth: "100%",
    width: "100% !important",
    height: "100% !important",
    top: "0 !important",
    paddingBottom: "0 !important",
    margin: 0,
  }),
  ".ant-modal-content": {
    borderRadius: "2px",
    ...(props.fullScreen && {
      width: "100% !important",
      height: "100% !important",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    }),
  },
  ".ant-modal-header, .ant-modal-footer, .ant-modal-body": {
    border: 0,
  },
  ".ant-modal-header": {
    padding: `${bodyPaddingX} ${bodyPaddingX} 0 ${bodyPaddingX}`,
    [MEDIA_QUERIES.w.ltSm]: {
      padding: `40px ${bodyPaddingXSm} 0 ${bodyPaddingXSm}`,
    },
    ...(props.fullScreen && {
      minHeight: "60px !important",
      padding: "20px 60px 0 20px !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: "0 0 auto",
    }),
  },
  ".ant-modal-body": {
    padding: 0,
    ...(props.fullScreen && {
      position: "relative",
      width: "100% !important",
      flex: "1 1 auto",
      [MEDIA_QUERIES.w.ltSm]: {
        overflowY: "scroll",
      },
    }),
  },
  ".ant-modal-footer": {
    padding: `0 ${bodyPaddingX} ${bodyPaddingX} ${bodyPaddingX}`,
    flex: "0 0 auto",
    [MEDIA_QUERIES.w.ltSm]: {
      padding: `0 ${bodyPaddingXSm} 24px ${bodyPaddingXSm}`,
    },
  },
  ".ant-modal-title": {
    display: "flex",
    alignItems: "center",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "130%",
    [MEDIA_QUERIES.w.ltSm]: {
      fontSize: "24px",
    },
    svg: {
      marginRight: "12px",
    },
  },
  ".ant-modal-close-x": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "58px",
    height: "58px",
  },
}));

type BodyProps = {
  spacer?: boolean;
  noMobilePaddingX?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  noPaddingX?: boolean;
  test?: boolean;
};
const Body = styled(
  ({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    spacer,
    noMobilePaddingX,
    noPaddingTop,
    noPaddingBottom,
    noPaddingX,
    test,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...props
  }) => <div {...props} />
)<BodyProps>((props) => ({
  padding: `40px ${bodyPaddingX}`,
  ...(props.spacer && {
    borderBottom: `1px solid ${COLORS.borders}`,
  }),
  [MEDIA_QUERIES.w.ltSm]: {
    padding: `40px ${bodyPaddingXSm}`,
    ...(props.noMobilePaddingX && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
  },
  ...(props.noPaddingTop && {
    paddingTop: "0 !important",
  }),
  ...(props.noPaddingX && {
    padding: "0 !important",
  }),
  ...(props.noPaddingBottom && {
    paddingBottom: "0 !important",
  }),
}));

const SubmitFooter = styled("div")<{ fullWidth?: boolean }>((props) => ({
  ...(props.fullWidth && {
    flex: 1,
  }),
  [MEDIA_QUERIES.w.ltSm]: {
    display: "flex",
    flexDirection: "column-reverse",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubmitButton = styled(({ noMargin, ...props }) => <Button {...props} />)<{
  noMargin?: boolean;
}>(({ noMargin }) => ({
  backgroundColor: colors.secondary[700],
  borderRadius: "4px",
  "&.ant-btn-primary": {
    "&:disabled, &:disabled:hover": {
      backgroundColor: colors.neutral[500],
    },
    "&:hover": {
      backgroundColor: colors.secondary[400],
    },
    "&:focus": {
      backgroundColor: colors.secondary[500],
    },
  },
  marginLeft: "24px",
  ...(noMargin && {
    margin: "0",
  }),
  display: "inline",
  [MEDIA_QUERIES.w.ltSm]: {
    alignSelf: "flex-end",
  },
}));

const Subtitle = styled("div")<{ noMarginTop?: boolean }>(
  ({ noMarginTop }) => ({
    fontSize: "17px",
    fontWeight: 300,
    marginTop: !noMarginTop && "12px",
    marginBottom: 0,
    lineHeight: "130%",
  })
);

const Footer = styled("div")({
  display: "flex",
  alignItems: "center",
  [MEDIA_QUERIES.w.ltLg]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

const FooterLeft = styled("div")({
  textAlign: "left",
  maxWidth: "500px",
});

const FooterRight = styled("div")<{ centered?: boolean }>(({ centered }) => ({
  display: "flex",
  alignItems: "center",
  [MEDIA_QUERIES.w.ltLg]: {
    marginTop: "24px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    marginLeft: "auto",
    ...(centered && {
      marginRight: "auto",
      textAlign: "center",
    }),
  },
  [MEDIA_QUERIES.w.ltSm]: {
    width: "100%",
  },
}));

export {
  SubmitFooter,
  SubmitButton,
  Subtitle,
  Footer,
  FooterLeft,
  FooterRight,
  Body,
};
