import _ from 'lodash'
import React, { useState } from 'react'
import { useVeroFlags } from 'shared/utils/launchDarkly'
import { useGetLumenFormulas, useGetLumenFormulaRevision, useListLumenV2Formulas } from 'manager/hooks/api'
import { Toggle } from 'shared/components/vega/Toggle'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { Loader } from 'shared/components/LargeLoader'
import { Workflow, Workflows } from 'shared/components/vega/WorkflowStudio'
import LumenV1Reader from './LumenV1Reader'
import LumenEditor from './LumenEditor'

function getLegacyLumenFormulaRevision (property) {
  const { lumenFormulas, isLoading: areFormulasLoading } = useGetLumenFormulas(!!property)
  const { id, revisionId } = _.find(lumenFormulas, formula => _.includes(formula?.properties, property)) || {}
  const { lumenFormulaRevision, isLoading: isRevisionLoading } = useGetLumenFormulaRevision(id, revisionId)

  return {
    legacyFormulaRevision: lumenFormulaRevision,
    isLoading: !lumenFormulaRevision && (areFormulasLoading || isRevisionLoading)
  }
}

const LumenFormulas = ({ formulas, readOnlyFormulas = [], enableExpressionEditor = false }) => {
  const builder = (formula, readOnly) => {
    return (
      <Workflow workflow={formula} readOnly={readOnly}>
        <LumenEditor formula={formula} canEdit={enableExpressionEditor && !readOnly} />
      </Workflow>
    )
  }

  return (
    <Workflows workflows={formulas} readOnlyWorkflows={readOnlyFormulas} builder={builder} />
  )
}

const LumenStudio = ({ location, readOnlyLocations = [], property = undefined }) => {
  const { enableExpressionEditor } = useVeroFlags()
  const [showFormula, setShowFormula] = useState(enableExpressionEditor)

  const { lumenFormulas: lumenFormulasByLocation, isLoading: isListLumenFormulasLoading } = useListLumenV2Formulas(location, true)

  const readOnlyLumenFormulas = _.chain(readOnlyLocations).map(l => {
    const { lumenFormulas } = useListLumenV2Formulas(l, true)
    return lumenFormulas
  }).flatten().uniqBy('id').value()

  const { legacyFormulaRevision, isLoading: isLegacyFormulaRevisionLoading } = getLegacyLumenFormulaRevision(property)
  const isLoading = isListLumenFormulasLoading || isLegacyFormulaRevisionLoading

  return (
    <div className='vega container'>
      <Loader loading={isLoading}>
        {property && legacyFormulaRevision && <header className='vega flex'>
          <div className='vega title'>
            <div>
              <span>{legacyFormulaRevision?.name}</span>
              <span className='vega badge'>{legacyFormulaRevision?.status}</span>
            </div>

            <div className='vega sub-title'>{legacyFormulaRevision?.createdBy}</div>
          </div>

          {legacyFormulaRevision?.updatedAt && <Timestamp timestamp={legacyFormulaRevision?.updatedAt} />}
        </header>}

        <div className='vega content'>
          {property && legacyFormulaRevision && <div className='vega actions'>
            <Toggle label='Show Formula' checked={showFormula} onChange={() => setShowFormula(!showFormula)} />
          </div>}

          {(showFormula || !property) && <LumenFormulas
            formulas={lumenFormulasByLocation}
            readOnlyFormulas={readOnlyLumenFormulas}
            enableExpressionEditor={enableExpressionEditor}
          />}

          {!showFormula && property && <LumenV1Reader lumenFormulaRevision={legacyFormulaRevision} />}
        </div>
      </Loader>
    </div>
  )
}

export default LumenStudio
