import isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'

import type { IncludedIncomeStream } from 'renter/interfaces/api/income'
import FlexContainer from 'shared/components/FlexContainer'
import {
  MediumTitle,
  SmallText,
} from 'shared/components/Typography'
import { COLORS } from 'shared/config/constants'
import { printDollarsFromCents } from 'shared/utils/dollar-print'

import { IncomeStreamsTable } from './IncomeStreamsTable'
import Tile from 'shared/components/Tile'

interface IncludedIncomeProps {
  streams: IncludedIncomeStream[]
  canViewOcrResults: boolean
  canEditOcrResults: boolean
}

export const IncludedIncomeSection = ({ streams, canViewOcrResults, canEditOcrResults }: IncludedIncomeProps) => {
  return (
    <Tile
      id="verified-income"
      header={{
        title: <MediumTitle>Verified Income</MediumTitle>,
        subtitle: !isEmpty(streams) && (
          <FlexContainer flexDirection="column">
            <SmallText extraStrong>Total Projected Annual Income</SmallText>
            <MediumTitle style={{ color: COLORS.violetTheme.lightViolet2 }}>
              {printDollarsFromCents(
                sumBy(
                  streams,
                  ({ estimatedAnnualGrossAmount, estimatedAnnualNetAmount }) =>
                    estimatedAnnualGrossAmount ?? estimatedAnnualNetAmount ?? 0,
                ),
              )}
            </MediumTitle>
          </FlexContainer>
        ),
        withoutBorderBottom: true,
      }}
      rounded
    >
      <IncomeStreamsTable streams={streams} type="included" canViewOcrResults={canViewOcrResults} canEditOcrResults={canEditOcrResults} />
    </Tile>
  )
}
