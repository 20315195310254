import React from "react";

import _ from "lodash";

import { Row, Col } from "antd";
import { Modal } from "shared/components/Modal";
import useModal from "shared/hooks/useModal";
import { mapIncomeStreamVerificationMethodToType } from "shared/utils/employmentAndIncome";
import { Form, Formik, FormikHelpers } from "formik";
import { Input, DatePicker, VeroFormField } from "shared/components/Form";
import { convertCentsToDollars } from "shared/utils/ui";

import * as Yup from "yup";

import type { Income } from "renter/interfaces/api/income";
import { usePatchOcrResultById } from "shared/hooks/api";
import { RevertButton } from "../styled";

interface EditPaystubModalProps {
  stream: Income;
  submit: (values) => void;
}

const validationSchema = Yup.object().shape({
  type: Yup.string(),
  payer: Yup.string().nullable(),
  payPeriodStartDate: Yup.string().nullable(),
  payPeriodEndDate: Yup.string().nullable(),
  payDate: Yup.string().nullable(),
  netAmount: Yup.number().nullable(),
  grossAmount: Yup.number().nullable(),
});

const EditPaystubModal = ({
  stream,
  submit,
}: EditPaystubModalProps) => {
  const { selfApi: { closeDialog } } = useModal()
  const { patchOcrResultById } = usePatchOcrResultById()
  const initialValues = {
    type: mapIncomeStreamVerificationMethodToType(stream.incomeVerificationMethod),
    payer: stream.description,
    payPeriodStartDate: stream.periodStart,
    payPeriodEndDate: stream.periodEnd,
    payDate: stream.dateReceived,
    netAmount: stream.netAmount ? convertCentsToDollars(stream.netAmount) : null,
    grossAmount: stream.grossAmount ? convertCentsToDollars(stream.grossAmount) : null,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount={true}
      onSubmit={(values) => {
        const filteredValues = _
          .chain(values).pickBy(function(value, key) {
            return key !== 'type' && initialValues[key] !== value;
          })
          .mapValues((value) => (value === "" ? null : value))
          .value();
        submit({
          id: stream.sourceOcrResult,
          data: filteredValues,
        });
      }}
      enableReinitialize
    >
      {({ isValid, submitForm }) => {
        return (
          <Form>
            <Modal
              title="Edit supporting income"
              submitButtonLabel="Save"
              cancelLinkLabel="Cancel"
              footer={
                <RevertButton
                  onClick={() => {
                    patchOcrResultById({
                      id: stream.sourceOcrResult,
                      data: {
                        payer: null,
                        payPeriodStartDate: null,
                        payPeriodEndDate: null,
                        payDate: null,
                        grossAmount: null,
                        netAmount: null,
                      },
                    })
                    closeDialog()
                  }}
                >
                  Revert changes
                </RevertButton>
              }
              submit={submitForm}
              centered
              showSubmitButton
              submitButtonDisabled={!isValid}
              width="650px"
            >
              <Modal.Body>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="type"
                      name="type"
                      label="Type"
                      data-testid="type-input"
                      disabled
                    />
                  </Col>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="payer"
                      name="payer"
                      label="Payer Name"
                      data-testid="payer-input"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={DatePicker}
                      id="payPeriodStartDate"
                      name="payPeriodStartDate"
                      label="Pay Period Start"
                      data-testid="payPeriodStartDate-input"
                    />
                  </Col>
                  <Col md={12}>
                    <VeroFormField
                      as={DatePicker}
                      id="payPeriodEndDate"
                      name="payPeriodEndDate"
                      label="Pay Period End"
                      data-testid="payPeriodEndDate-input"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={DatePicker}
                      id="payDate"
                      name="payDate"
                      label="Pay Date"
                      data-testid="payDate-input"
                    />
                  </Col>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="netAmount"
                      name="netAmount"
                      label="Net Amount"
                      data-testid="netAmount-input"
                      disableMaskedInput
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="grossAmount"
                      name="grossAmount"
                      label="Gross Amount"
                      data-testid="grossAmount-input"
                      disableMaskedInput
                    />
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPaystubModal;
