import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

export const EMAILS_WITH_INCOME_GRAPH_VIEW_PERMISSION = [
  'alex@sayvero.com',
  'anne@sayvero.com',
  'foster.ho@sayvero.com',
  'michelle@sayvero.com',
  'conner.key@sayvero.com',
  'chelsea.todd@sayvero.com',
  'aj.tjaden@sayvero.com',
  'trevor.nathaniel@sayvero.com',
  'eszter@sayvero.com',
  'daniel.jonguitud@sayvero.com',
  'jerome.tham@sayvero.com',
  'chris.stark@sayvero.com',
  'carson@sayvero.com',
  'conor.chocheles@sayvero.com',
  'laura@sayvero.com',
  'katherine.herrick@sayvero.com',
  'reilly.haden@sayvero.com',
  'james.farillo@sayvero.com',
  'elijah.householder@sayvero.com',
  'taffy@sayvero.com',
  'dusan@sayvero.com',
  'grace.fernandez@sayvero.com',
  'ben@sayvero.com',
  'keith@sayvero.com',
  'lou@sayvero.com',
  'michael.wagnon@sayvero.com',
  'kevin@sayvero.com',
  'jpeter@sayvero.com',
  'veronica@sayvero.com',
  'kyle@sayvero.com',
  'stacy@sayvero.com',
  'dkuether@sayvero.com',
]

export const PERMISSIONS = Object.freeze({
  INTEGRATIONS_ADMIN: "integrationsAdmin",
  CAN_UPLOAD_PROPERTY_LOGO: "canUploadPropertyLogo",
  LUMEN_ADMIN: "lumenAdmin",
  LUMEN_FORMULA_EXPORTER: "lumenFormulaExporter",
  SENSITIVE_DATA_IN_LUMEN: "canAccessSensitiveDataInLumen",
  CAN_VIEW_FULL_SSN: "viewFullSsn",
  CAN_TRIGGER_COUNTY_CRIMINAL_SEARCH: "canTriggerCountyCriminalSearch",
  CAN_APPROVE_DEAL_ON_PENDING_COUNTY_CRIMINAL_SEARCH:
    "canApproveDealOnPendingCountyCriminalSearch",
  CAN_CUSTOMIZE_EMAILS: "customizeEmails",
  CAN_INVITE_LEAD: "canInviteLead",
  CAN_MANAGE_PREQUALIFICATION_ON_PROPERTY:
    "canManagePrequalificationOnProperty",
  CAN_INVITE_LEADS_TO_APPLY: "canInviteLeadsToApply",
  CAN_OVERRIDE_FEES_ON_LEASE_BUILD: "canOverrideFeesOnLeaseBuild",
  CAN_USE_BLUEMOON: "canUseBluemoon",
  CAN_OVERRIDE_APPLICANT_PREFERRED_LEASE_DATE_ON_UNIT_ASSIGNMENT:
    "canOverrideApplicantPreferredLeaseDateOnUnitAssignment",
  CAN_RECALCULATE_LUMEN: "canRecalculateLumen",
});

/**
 * Prefix error codes for error messages related to auth calls.
 */
export const LOGIN_ERROR_CODES = Object.freeze({
  expired: "EXPIRED",
  accountDisabled: "ACCOUNT_DISABLED",
  userNotFound: "USER_NOT_FOUND",
  invitationMismatch: "INVITATION_MISMATCH",
  noManagerAccount: "NO_MANAGER_ACCOUNT",
  noRenterAccount: "NO_RENTER_ACCOUNT",
  noAdminAccount: "NO_ADMIN_ACCOUNT",
  noOpenApiAccount: "NO_OPEN_API_ACCOUNT",
  noLinkHash: "NO_LINK_HASH",
  invalidSSOType: "INVALID_SSO_TYPE",
});

// SSO
export const SSO_IDPS = Object.freeze({
  awsCognito: "AWS_COGNITO",
  linkedIn: "LINKEDIN",
  vegaAuth: "VEGA_AUTH",
});

export const SSO_PROVIDERS = Object.freeze({
  google: "Google",
  facebook: "Facebook",
  linkedIn: "Linkedin",
  ripplingVero: "RipplingVero",
});

export const SSO_PROVIDERS_VIEW_CONFIG = Object.freeze({
  [SSO_PROVIDERS.google]: {
    Icon: Icon.GoogleLogo,
    buttonLabel: "SIGN IN WITH GOOGLE",
    color: COLORS.googleButtonLabel,
  },
  [SSO_PROVIDERS.facebook]: {
    Icon: Icon.FacebookLogo,
    buttonLabel: "SIGN IN WITH FACEBOOK",
    color: COLORS.facebookButtonLabel,
  },
  [SSO_PROVIDERS.linkedIn]: {
    Icon: Icon.LinkedInLogoSmall,
    buttonLabel: "SIGN IN WITH LINKEDIN",
    color: COLORS.linkedInButtonLabel,
  },
  [SSO_PROVIDERS.ripplingVero]: {
    Icon: Icon.RipplingLogo,
    buttonLabel: "SIGN IN WITH RIPPLING",
    color: COLORS.black,
  },
});
